import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setToken, userService } from "../helpers/UserService"; // Import userService or adjust the import path

function LoginPage() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSubmitted(true);
  
    const { username, password } = formData;
  
    if (!(username && password)) {
      return;
    }
    
    userService.logout();
    setLoading(true);
  
    try {
      const user = await userService.login(username, password);
      await setToken(user);
      
      navigate("/")
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="">
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className={`${
                  submitted && !formData.username
                    ? "border-red-500"
                    : "border-gray-300"
                } appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm`}
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
              />
              {submitted && !formData.username && (
                <p className="mt-2 text-sm text-red-600" id="username-error">
                  Username is required
                </p>
              )}
            </div>
            <div className="pt-5">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`${
                  submitted && !formData.password
                    ? "border-red-500"
                    : "border-gray-300"
                } appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm`}
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              {submitted && !formData.password && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  Password is required
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={`${
                loading ? "cursor-wait bg-gray-400 hover:bg-gray-400" : "cursor-pointer"
              } group relative w-full flex justify-center py-2 px-4 border border-transparent text-m font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              disabled={loading}
            >
              Login
            </button>
          </div>
        </form>
        {error && (
          <div className="text-red-600 mt-2 text-sm text-center">{error}</div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
