//Create Variation Page

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { API_BASE_URL } from "../config";
import {
  fetchVariation,
  fetchVariationData,
} from "../helpers/VariationService";

const CreateVariation = () => {
  const navigate = useNavigate();
  const productId = useLocation().state.productId;
  const { search } = useLocation();
  const variationId = new URLSearchParams(search).get("variationId");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEdit = variationId !== null;

  const [formData, setFormData] = useState({
    storage: "",
    totalValue: "",
    pricePerQuarter: "",
    pricePerMonth: "",
    pricePerFortnight: "",
    pricePerWeek: "",
    pricePerDay: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // If the field is meant to contain only numbers, we use a regex to enforce that.
    if (['totalValue', 'pricePerQuarter', 'pricePerMonth', 'pricePerFortnight', 'pricePerWeek', 'pricePerDay'].includes(name)) {
      // This regex allows digits, a single decimal point, and nothing else.
      const re = /^[0-9]*(\.[0-9]*)?$/;
  
      // If value is empty or matches regex, we proceed to set it.
      if (value === '' || re.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      // For all other fields, we set the value normally.
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchVariationData(variationId, setFormData);
    }
  }, [isEdit, productId, variationId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const variationData = {
      storage: formData.storage,
      totalValue: parseFloat(formData.totalValue),
      pricePerQuarter: parseFloat(formData.pricePerQuarter),
      pricePerMonth: parseFloat(formData.pricePerMonth),
      pricePerFortnight: parseFloat(formData.pricePerFortnight),
      pricePerWeek: parseFloat(formData.pricePerWeek),
      pricePerDay: parseFloat(formData.pricePerDay),
    };

    const apiUrl = isEdit
      ? API_BASE_URL + `/api/v1/product/${productId}/variation/${variationId}`
      : API_BASE_URL + `/api/v1/product/${productId}/variation`;

    const method = isEdit ? "PUT" : "POST";

    if (
      fetchVariation(apiUrl, method, variationData, isEdit, setIsSubmitting)
    ) {
      navigate(`/product/${productId}`);
    }
  };

  return (
    <div className="mx-auto p-8 w-full max-w-md">
      <h1 className="text-3xl font-bold mb-8">
        {isEdit ? "Edit" : "Create"} Product Variation
      </h1>

      <form onSubmit={handleSubmit}>
        {/* Storage */}
        <div className="mb-4">
          <label htmlFor="storage" className="block mb-2 text-sm font-bold">
            Storage:
          </label>
          <select
            id="storage"
            name="storage"
            value={formData.storage}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          >
            <option value="">Select storage</option>
            <option value="64gb">64GB</option>
            <option value="128gb">128GB</option>
            <option value="256gb">256GB</option>
            <option value="512gb">512GB</option>
            <option value="1tb">1TB</option>
            <option value="2tb">2TB</option>
          </select>
        </div>

        {/* Total Value */}
        <div className="mb-4">
          <div className="mb-4 font-semibold">Numbers only here: (no $)</div>
          <label htmlFor="totalValue" className="block mb-2 text-sm font-bold">
            Total Value:
          </label>
          <input
            type="text"
            id="totalValue"
            name="totalValue"
            value={formData.totalValue}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Total Value"
            required
          />
        </div>

        {/* Price per Quarter */}
        <div className="mb-4">
          <label
            htmlFor="pricePerQuarter"
            className="block mb-2 text-sm font-bold"
          >
            Price per Quarter:
          </label>
          <input
            type="text"
            id="pricePerQuarter"
            name="pricePerQuarter"
            value={formData.pricePerQuarter}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Price per Quarter"
            required
          />
        </div>

        {/* Price per Month */}
        <div className="mb-4">
          <label
            htmlFor="pricePerMonth"
            className="block mb-2 text-sm font-bold"
          >
            Price per Month:
          </label>
          <input
            type="text"
            id="pricePerMonth"
            name="pricePerMonth"
            value={formData.pricePerMonth}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Price per Month"
            required
          />
        </div>

        {/* Price per Fortnight */}
        <div className="mb-4">
          <label
            htmlFor="pricePerFortnight"
            className="block mb-2 text-sm font-bold"
          >
            Price per Fortnight:
          </label>
          <input
            type="text"
            id="pricePerFortnight"
            name="pricePerFortnight"
            value={formData.pricePerFortnight}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Price per Fortnight"
            required
          />
        </div>

        {/* Price per Week */}
        <div className="mb-4">
          <label
            htmlFor="pricePerWeek"
            className="block mb-2 text-sm font-bold"
          >
            Price per Week:
          </label>
          <input
            type="text"
            id="pricePerWeek"
            name="pricePerWeek"
            value={formData.pricePerWeek}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Price per Week"
            required
          />
        </div>

        {/* Price per Day */}
        <div className="mb-4">
          <label htmlFor="pricePerDay" className="block mb-2 text-sm font-bold">
            Price per Day:
          </label>
          <input
            type="text"
            id="pricePerDay"
            name="pricePerDay"
            value={formData.pricePerDay}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            placeholder="Price per Day"
            required
          />
        </div>

        <div className="flex justify-between">
          <button
            type="button" // Use type="button" for the cancel button
            onClick={() => navigate(-1)} // Navigate back to the previous page
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition px-8"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`${
              isSubmitting
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-green-500 text-white"
            } p-2 rounded hover:bg-green-600 transition px-8`}
            disabled={isSubmitting} // Use the isSubmitting state variable
          >
            {isEdit ? "Update" : "Create"} Product Variation
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateVariation;
