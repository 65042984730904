import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="bg-gray-800 text-white p-6 h-screen">
      <h1 className="text-2xl">ProTechRentals</h1>
      <h1 className="text-xl mb-4">Admin Portal</h1>
      <div className="border-b border-gray-500 mb-4"></div>
      <ul>
        <Link to="/applications">
          <li className="mb-2 hover:bg-gray-700 p-2 rounded transition">
            Applications
          </li>
        </Link>
        <Link to="/product">
          <li className="mb-2 hover:bg-gray-700 p-2 rounded transition">
            Product
          </li>
        </Link>

        {/* <li className="mb-2 hover:bg-gray-700 p-2 rounded transition">
          <Link to="/variation">Variation</Link>
        </li>
        <li className="mb-2 hover:bg-gray-700 p-2 rounded transition">
          <Link to="/physical-device">Physical Device</Link>
        </li>
        <li className="mb-2 hover:bg-gray-700 p-2 rounded transition">
          <Link to="/customers">Customers</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
