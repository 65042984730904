import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import {
  updateProduct,
  createProduct,
  getProduct,
} from "../helpers/ProductService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const CreateProduct = () => {
  const { search } = useLocation();
  const productId = new URLSearchParams(search).get("productId");
  const isEditMode = !!productId;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    image: "", // base64 encoded image
    title: "",
    colors: [], // array of color objects {name, hex, id}
    rentalPeriods: [], // array of rental periods
    images: [], // array of base64 encoded images, their filenames, and order
    bestSeller: false, // boolean for best seller
    description1: "", // first description text
    description2: "", // second description text
    inTheBox: "", // base64 encoded in the box image
    archived: false, // boolean for archived status
  });

  useEffect(() => {
    if (productId) {
      getProduct(productId, setFormData);
    }
  }, [productId]);

  const [colorName, setColorName] = useState("");
  const [colorHex, setColorHex] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (e.target.id === "image") {
          setFormData({ ...formData, image: reader.result });
        } else {
          setFormData({ ...formData, inTheBox: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
    console.log(file);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "bestSeller") {
      setFormData({ ...formData, bestSeller: checked });
    } else if (name === "monthUpFront") {
      setFormData({ ...formData, monthUpFront: checked });
    } else {
      const rentalPeriods = [...formData.rentalPeriods];

      if (checked) {
        rentalPeriods.push(value);
      } else {
        const index = rentalPeriods.indexOf(value);
        if (index !== -1) {
          rentalPeriods.splice(index, 1);
        }
      }

      setFormData({ ...formData, [name]: rentalPeriods });
    }
  };

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleColorAdd = () => {
    if (colorName && colorHex) {
      const updatedColors = [
        ...formData.colors,
        { name: colorName, hex: colorHex },
      ];
      setFormData({ ...formData, colors: updatedColors });
      setColorName("");
      setColorHex("");
    }
  };

  const handleColorDelete = (index) => {
    const updatedColors = [...formData.colors];
    updatedColors.splice(index, 1);
    setFormData({ ...formData, colors: updatedColors });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log("FormData");
    console.log(formData);
    console.log("ConstructProductData");
    console.log(constructProductData());

    try {
      const productData = constructProductData();
      console.log("ProductData");
      console.log(productData);
      if (isEditMode) {
        // In Edit mode, send a PUT request
        const response = await updateProduct(productData, productId);
        handleResponse(response);
      } else {
        // In Create mode, send a POST request
        const response = await createProduct(productData);
        handleResponse(response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Extracted function to construct product data
  const constructProductData = () => {
    const iconBase64 = formData.image.split(",")[1];
    const images = formData.images.map((imageData) => ({
      data: imageData.data.split(",")[1],
      orderNumber: imageData.orderNumber ?? 1,
      fileName: imageData.fileName ?? "No Title",
    }));
    const inTheBoxBase64 = formData.inTheBox.split(",")[1];

    return {
      icon: iconBase64,
      name: formData.title,
      colors: formData.colors,
      rentalPeriods: formData.rentalPeriods,
      images: images,
      description1: formData.description1,
      description2: formData.description2,
      bestSeller: formData.bestSeller,
      inTheBox: inTheBoxBase64,
      monthUpFront: formData.monthUpFront,
      archived: formData.archived,
    };
  };

  // Extracted function to handle API response
  const handleResponse = async (response) => {
    if (response.ok) {
      const createdProduct = await response.json();
      navigate(`/product/${createdProduct.id}`);
    } else {
      console.error("Product creation/update failed");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file for single image upload
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Convert the image to base64 and append to the images array in formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          images: [
            ...prevFormData.images,
            {
              data: reader.result, // Base64 image data
              orderNumber: prevFormData.images.length + 1, // Assign next order number
              fileName: file.name,
            },
          ],
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleMultipleFileChange = (e) => {
  //   const files = e.target.files;
  //   if (files.length > 0) {
  //     const promises = Array.from(files).map((file) => {
  //       return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           resolve(reader.result);
  //         };
  //         reader.onerror = reject;
  //         reader.readAsDataURL(file);
  //       });
  //     });

  //     Promise.all(promises)
  //       .then((base64Files) => {
  //         setFormData({ ...formData, images: base64Files });
  //       })
  //       .catch((error) => {
  //         console.error("Error reading files:", error);
  //       });
  //   }
  // };

  const handleDragEnd = (result) => {
    console.log(formData);
    // Destructure the source and destination from the result
    const { source, destination } = result;

    // If there is no destination (the item was dropped outside), return without doing anything
    if (!destination) {
      return;
    }

    // If the item is dropped in the same place, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Reorder the images
    setFormData((prevFormData) => {
      const newImages = Array.from(prevFormData.images);
      // Move the item
      const [reorderedItem] = newImages.splice(source.index, 1);
      newImages.splice(destination.index, 0, reorderedItem);

      // Update order numbers to match the new index positions
      const updatedImages = newImages.map((item, index) => ({
        ...item,
        orderNumber: index + 1,
      }));

      return {
        ...prevFormData,
        images: updatedImages,
      };
    });
  };

  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => {
      const newImages = prevFormData.images.filter((_, i) => i !== index);

      const reOrderedImages = newImages.map((image, newIndex) => ({
        ...image,
        orderNumber: newIndex + 1,
      }));

      return {
        ...prevFormData,
        images: reOrderedImages,
      };
    });
  };

  return (
    <div className="mx-auto p-32 w-full">
      <h1 className="text-3xl font-bold mb-8">
        {isEditMode ? "Edit Product" : "Create New Product"}
      </h1>

      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          // Check if the key pressed is the Enter key
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
      >
        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-8 pt-8">
            Product Image (1MB max):
          </h2>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="p-2 border rounded w-full"
          />
          {formData.image && (
            <img src={formData.image} alt="Product" className="max-w-xl mt-4" />
          )}
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-8 pt-8">
            In The Box Image (1MB max):
          </h2>
          <input
            type="file"
            id="inTheBox"
            name="inTheBox"
            onChange={handleFileChange}
            className="p-2 border rounded w-full"
          />
          {formData.inTheBox && (
            <img
              src={formData.inTheBox}
              alt="inTheBox"
              className="max-w-xl mt-4"
            />
          )}
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-8 pt-8">Title:</h2>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="p-2 border rounded w-full"
          />
        </div>

        {/* Checkbox for BestSeller */}
        <div className="mb-4 pb-8">
          <label className="inline-flex items-center cursor-pointer bg-gray-100 p-2 rounded-md hover:bg-gray-200">
            <input
              type="checkbox"
              name="bestSeller"
              checked={formData.bestSeller}
              onChange={handleCheckboxChange}
              className="form-checkbox text-blue-600 h-5 w-5 cursor-pointer transition-transform duration-200 ease-in-out
                 checked:scale-125 checked:border-gray-700 checked:shadow-outline mr-2"
            />
            <span className="ml-2">Best Seller</span>
          </label>
        </div>

        {/* Handle colors */}
        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-8 pt-8">Colors:</h2>
          <div className="flex items-center space-x-2 mb-2">
            <div
              className={`w-20 h-10 rounded-full border hover:border-indigo-600 cursor-pointer`}
              style={{ backgroundColor: colorHex }}
            ></div>
            <input
              type="text"
              placeholder="Color name"
              name="colorName"
              value={colorName}
              onChange={(e) => setColorName(e.target.value)}
              className="p-2 border rounded w-full"
            />
            <input
              type="text"
              placeholder="Hex code"
              name="colorHex"
              value={colorHex}
              onChange={(e) => setColorHex(e.target.value)}
              className="p-2 border rounded w-full"
            />
            <button
              type="button"
              onClick={handleColorAdd}
              className="bg-blue-500 whitespace-nowrap text-white p-2 rounded hover:bg-blue-600 transition"
            >
              Add Color
            </button>
          </div>
          <ul>
            {formData.colors.map((color, index) => (
              <li key={index} className="flex items-center space-x-2 py-2">
                <div className="mx-2">
                  <div
                    className={`w-10 h-10 rounded-full border `}
                    style={{ backgroundColor: color.hex }}
                  />
                </div>
                {color.name} ({color.hex})
                <div className="bg-gray-200 hover:bg-red-300 px-4 py-1 rounded-md">
                  <button
                    type="button"
                    onClick={() => handleColorDelete(index)}
                    className="text-gray-600 hover:text-red-700"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Checkboxes for rental periods */}
        <RentalPeriodCheckboxes
          rentalPeriods={formData.rentalPeriods}
          handleCheckboxChange={handleCheckboxChange}
        />

        {/* monthUpFront section checkbox large */}
        <h2 className="text-2xl font-bold mb-8 pt-8">
          Charge One Month Up Front
        </h2>
        <div className="mb-4 pb-8">
          <label className="inline-flex items-center cursor-pointer bg-gray-100 p-2 rounded-md hover:bg-gray-200">
            <input
              type="checkbox"
              name="monthUpFront"
              checked={formData.monthUpFront}
              onChange={handleCheckboxChange}
              className="form-checkbox text-blue-600 h-5 w-5 cursor-pointer transition-transform duration-200 ease-in-out
                 checked:scale-125 checked:border-gray-700 checked:shadow-outline mr-2"
            />
            <span className="ml-2">Month Up Front</span>
          </label>
        </div>

        <h2 className="text-2xl font-bold mb-8 pt-8"> Descriptions:</h2>
        {/* New text area for description1 */}
        <div className="mb-4">
          <label
            htmlFor="description1"
            className="block mb-2 text-sm font-bold"
          >
            Description 1:
          </label>
          <textarea
            id="description1"
            name="description1"
            value={formData.description1}
            onChange={handleDescriptionChange}
            rows="4"
            className="p-2 border rounded w-full"
          />
        </div>

        {/* New text area for description2 */}
        <div className="mb-4">
          <label
            htmlFor="description2"
            className="block mb-2 text-sm font-bold"
          >
            Description 2:
          </label>
          <textarea
            id="description2"
            name="description2"
            value={formData.description2}
            onChange={handleDescriptionChange}
            rows="4"
            className="p-2 border rounded w-full"
          />
        </div>

        <h2 className="text-2xl font-bold mb-8 pt-8">Product Images:</h2>
        {/* Carousel for Product Images */}
        <div className="mb-4">
          {/* Hidden file input */}
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={(e) => handleImageChange(e)}
          />

          {/* Visible label styled as a button with Tailwind CSS */}
          <label
            htmlFor="fileInput"
            className="p-4cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add a product image
          </label>
          <div className="my-8">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable-images">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2" // Adds space between draggable items
                  >
                    {formData.images.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex items-center p-4 border border-gray-300 rounded shadow cursor-move" // Styling for each draggable item
                          >
                            <div className="flex-grow font-medium">
                              {image.orderNumber}. {image.fileName}
                            </div>
                            <button
                              type="button"
                              onClick={() => handleRemoveImage(index)}
                              className="ml-4 bg-gray-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            >
                              Remove
                            </button>
                            {/* Image thumbnail or other content can go here */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {formData.images.length > 0 && (
            <div className="lg:w-1/2 mx-auto">
              <Carousel
                showArrows={true}
                showThumbs={true}
                showStatus={true}
                infiniteLoop={false}
              >
                {formData.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image.data}
                      alt={`Product ${index}`}
                      className="lg:max-w-lg mx-auto rounded-lg"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>

        <div className="flex justify-between pt-16">
          <button
            type="button" // Use type="button" for the cancel button
            onClick={() => navigate(-1)} // Navigate back to the previous page
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition px-8"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`${
              isSubmitting
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-green-500 text-white"
            } p-2 rounded hover:bg-green-600 transition px-8`}
            disabled={isSubmitting} // Use the isSubmitting state variable
          >
            {isEditMode ? "Update Product" : "Create Product"}
          </button>
        </div>
      </form>
    </div>
  );
};

const RentalPeriodCheckboxes = ({ rentalPeriods, handleCheckboxChange }) => {
  const periods = ["Daily", "Weekly", "Fortnightly", "Monthly", "Quarterly"];

  return (
    <div className="mb-4">
      <h2 className="text-2xl font-bold mb-8 pt-8">Rental Periods:</h2>
      <div>
        {periods.map((period) => (
          <label key={period} className="px-4">
            <input
              type="checkbox"
              value={period}
              name="rentalPeriods"
              checked={rentalPeriods.includes(period)}
              onChange={handleCheckboxChange}
            />{" "}
            {period.charAt(0).toUpperCase() + period.slice(1)}
          </label>
        ))}
      </div>
    </div>
  );
};

export default CreateProduct;
