import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useProductData from "../hooks/useProductData";
import {API_BASE_URL} from "../config";
import { fetchExistingPhysicalDevice, sendPhysicalDeviceData } from "../helpers/PhysicalDeviceService";

const CreatePhysicalDevice = () => {
  const state = useLocation().state;
  const variation = state.variation;
  const variationId = variation.id;
  const [colors, setColors] = useState([]);
  const productId = state.productId;
  const { products, setProducts } = useProductData(productId);
  const navigate = useNavigate();
  const physicalDeviceId = new URLSearchParams(useLocation().search).get(
    "physicalDeviceId"
  ); // Get the physical device ID from the URL, if any
  const isEditing = !!physicalDeviceId;
  const [isSubmitting, setIsSubmitting] = useState(false);

  // State for the form data
  const [formData, setFormData] = useState({
    id: "",
    imei: "",
    serial: "",
    colorId: "",
    colorName: "",
    colorHex: "",
  });

  useEffect(() => {
    if (products.length > 0) {
      setColors(products[0].colors);
    }

    if (isEditing) {
      fetchExistingPhysicalDevice(physicalDeviceId, setFormData);
    }
  }, [products, colors, isEditing, physicalDeviceId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const variationData = {
      imei: formData.imei,
      serial: formData.serial,
      color: {
        id: formData.colorId,
        name: formData.colorName,
        hex: formData.colorHex,
      },
      variation: { id: variationId }, // Associate the physical device with the variation
    };

    let apiURL = API_BASE_URL + "/api/v1/physicalDevice";
    let method = "POST";

    if (isEditing) {
      variationData.id = physicalDeviceId;
      apiURL = API_BASE_URL + `/api/v1/physicalDevice/${physicalDeviceId}`;
      method = "PUT";
    }

    if( sendPhysicalDeviceData(apiURL, method, variationData, setIsSubmitting) ) {
      navigate(-1); // Navigate back to the previous page
    } else {
      alert("There was an error submiting the data.")
    }
  };

  

  return (
    <div className="mx-auto p-8 w-full max-w-md">
      <h1 className="text-3xl font-bold mb-8">
        {isEditing ? "Edit Physical Device" : "Create Physical Device"}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="imei" className="block mb-2 text-sm font-bold">
            IMEI:
          </label>
          <input
            type="text"
            id="imei"
            name="imei"
            value={formData.imei}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="serial" className="block mb-2 text-sm font-bold">
            Serial:
          </label>
          <input
            type="text"
            id="serial"
            name="serial"
            value={formData.serial}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="colorId" className="block mb-2 text-sm font-bold">
            Color:
          </label>
          <select
            id="colorId"
            name="colorId"
            value={formData.colorId}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>
              Select a color
            </option>
            {colors.length > 0 &&
              colors.map((color) => (
                <option key={color.id} value={color.id}>
                  {color.name}
                </option>
              ))}
          </select>
        </div>

        <div className="flex justify-between">
          <button
            type="button" // Use type="button" for the cancel button
            onClick={() => navigate(-1)} // Navigate back to the previous page
            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition px-8"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`${
              isSubmitting
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-green-500 text-white"
            } p-2 rounded hover:bg-green-600 transition px-8`}
            disabled={isSubmitting} // Use the isSubmitting state variable
          >
            {isEditing ? "Update Physical Device" : "Create Physical Device"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePhysicalDevice;
