//New Physical Device Page

import React, { useState } from "react";
import CreatePhysicalDevice from "../components/CreatePhysicalDevice";


const NewPhysicalDevicePage = () => {
    return (
        <div>
            <CreatePhysicalDevice />
        </div>
    );
}

export default NewPhysicalDevicePage;