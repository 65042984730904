//Application Page

import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar";
import ApplicationList from "../components/ApplicationList";
import ProductSummary from "../components/ProductSummary";

export default function ApplicationPage() {
  const [filterStatus, setFilterStatus] = useState(["PendingApproval"]);


    return (
    <div>
        <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />
        <ApplicationList filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
        </div>
    </div>
    </div>

    );
}