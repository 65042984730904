// App.js
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductListPage from "./Pages/ProductListPage";
import ProductDetailsPage from "./Pages/ProductDetailPage";
import NewProductPage from "./Pages/NewProductPage";
import VariationPage from "./Pages/VariationPage";
import NewVariationPage from "./Pages/NewVariationPage";
import NewPhysicalDevicePage from "./Pages/NewPhysicalDevicePage";
import ApplicationPage from "./Pages/ApplicationPage";
import ApplicationDetailsPage from "./Pages/ApplicationDetailsPage";
import LoginPage from "./Pages/LoginPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<ApplicationPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/product/new" element={<NewProductPage />} />
        <Route path="/product" element={<ProductListPage />} />
        <Route path="/applications" element={<ApplicationPage />} />
        <Route path="/variation/:id" element={<VariationPage />} />
        <Route path="/variation/new" element={<NewVariationPage />} />
        <Route
          path="/physical-device/new"
          element={<NewPhysicalDevicePage />}
        />
        <Route path="/applications/:id" element={<ApplicationDetailsPage />} />
        <Route exact path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
