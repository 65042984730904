import { API_BASE_URL } from "../config";
import getAuthHeader from "./AuthHeader"
import { handleResponse } from "./HandleResponse";

export async function fetchVariationData(variationId, setFormData) {
  const authHeader = getAuthHeader();
  console.log("Auth Header:", authHeader);
  console.log(`Fetching data for variation ID: ${variationId}`);

  fetch(API_BASE_URL + `/api/v1/product/variation/${variationId}`, {
    method: "GET",
    headers: {
      ...authHeader,
      "Content-Type": "application/json",
    },
  })
  .then((response) => {
    console.log("Response status:", response.status);
    return handleResponse(response);
  })
    .then((response) => response.json())
    .then((data) => {
      setFormData({
        storage: data.storage,
        totalValue: String(data.totalValue),
        pricePerQuarter: String(data.pricePerQuarter),
        pricePerMonth: String(data.pricePerMonth),
        pricePerFortnight: String(data.pricePerFortnight),
        pricePerWeek: String(data.pricePerWeek),
        pricePerDay: String(data.pricePerDay),
      });
    })
    .catch((error) => {
      console.error("Error fetching variation data:", error);
    });
}

export async function fetchVariation(
  apiUrl,
  method,
  variationData,
  isEdit,
  setIsSubmitting
) {
  fetch(apiUrl, {
    method,
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
      
    },
    body: JSON.stringify(variationData),
  })
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .then((data) => {
      if (isEdit) {
        console.log("Variation updated:", data);
      } else {
        console.log("Variation created:", data);
      }
    })
    .catch((error) => {
      console.error(
        isEdit ? "Error updating variation:" : "Error creating variation:",
        error
      );
      return false;
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  return true;
}

export async function deleteVariation(product, variation, setProduct) {
  // Deletes the product
  console.log("Product ID: " + product.id);
  fetch(
    API_BASE_URL + `/api/v1/product/${product.id}/variation/${variation.id}`,
    {
      method: "DELETE",
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
        
      },
    }
  )
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .then((data) => {
      console.log(data); // Log the response data
      // Update the products array to remove the deleted product
      setProduct({
        ...product,
        variations: product.variations.filter((v) => v.id !== variation.id),
      });
    })
    .catch((error) => console.error("Error deleting product:", error));
}
