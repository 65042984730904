import { API_BASE_URL } from "../config";
import getAuthHeader from "./AuthHeader"
import { handleResponse } from "./HandleResponse";

export async function fetchPhysicalDevices(variation, setPhysicalDevices) {
  fetch(API_BASE_URL + `/api/v1/physicalDevice/variation/${variation.id}`, {
    headers: {
  ...getAuthHeader(),
  "Content-Type": "application/json",
  
},
  })
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .then((data) => {
      setPhysicalDevices(data);
    })
    .catch((error) => {
      console.error("Error fetching physical device data:", error);
    });
};

export async function deletePhysicalDevice(
  physicalDevice,
  physicalDevices,
  setPhysicalDevices
) {
  fetch(API_BASE_URL + `/api/v1/physicalDevice/${physicalDevice.id}`, {
    method: "DELETE",
    headers: {
  ...getAuthHeader(),
  "Content-Type": "application/json",
  
},
  })
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .then((data) => {
      setPhysicalDevices(
        physicalDevices.filter((d) => d.id !== physicalDevice.id)
      );
    })
    .catch((error) => console.error("Error deleting physical device:", error));
};

export async function fetchExistingPhysicalDevice(physicalDeviceId, setFormData) {
  fetch(API_BASE_URL + `/api/v1/physicalDevice/${physicalDeviceId}`, {
    method: "GET",
    headers: {
  ...getAuthHeader(),
  "Content-Type": "application/json",
  
},
  })
    .then((response) => handleResponse(response))
    .then(async (data) => {
      let jsonData = await data.json();
      setFormData({
        imei: jsonData.imei,
        serial: jsonData.serial,
        colorId: jsonData.color.id,
        colorName: jsonData.color.name,
        colorHex: jsonData.color.hex,
      });
    })
    .catch((error) => {
      console.error("Error fetching existing physical device:", error);
    });
};

export async function sendPhysicalDeviceData(
  apiURL,
  method,
  physicalDevice,
  setIsSubmitting
) {
  fetch(apiURL, {
    method,
    headers: {
  ...getAuthHeader(),
  "Content-Type": "application/json",
  
},
    body: JSON.stringify(physicalDevice),
  })
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error creating Physical Device:", error);
      return false;
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  return true;
};
