import { userService } from "./UserService";

export function handleResponse(response, dontLogout = false) {
  if (!response.ok) {
    if ((response.status === 401 || response.status === 403) && !dontLogout) {

      logout();
      window.location.pathname = "/login"
      console.log("401 response");
    }
    // Reject the promise with the response so that you can handle the error outside
    return Promise.reject(response);
  }

  // If the response is ok, simply return the response without consuming it
  return response;
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("user");
}
