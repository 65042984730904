function TableHeader({ columnKey, label, sortColumn, sortOrder, handleSort }) {
    return (
      <th
        className={`p-2 cursor-pointer ${
          sortColumn === columnKey ? "text-blue-500" : ""
        }`}
        onClick={() => handleSort(columnKey)}
      >
        {label} {sortColumn === columnKey && sortOrder === "asc" && "↑"}
        {sortColumn === columnKey && sortOrder === "desc" && "↓"}
      </th>
    );
  }

  export default TableHeader;