import { authHeader } from "./AuthHeader";
import { API_BASE_URL } from "../config";
import CryptoJS from "crypto-js";
import { handleResponse } from "./HandleResponse";
export const userService = {
  login,
  logout,
};

function sha256(input) {
  const hash = CryptoJS.SHA256(input);
  return hash.toString(CryptoJS.enc.Hex);
}

async function login(username, password) {
  console.log("Login function");

  let encryptedPassword = sha256(password)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username, password: encryptedPassword }) // Sending credentials in the body
  };

  try {
    const response = await fetch(API_BASE_URL + `/api/v1/login`, requestOptions);
    const data = await handleResponse(response);
    
    return await response.text()
  } catch (error) {
    console.error("Login failed", error);
    throw error;
  }
}

export async function setToken(token) {
  const trimmedToken = token.trim(); // This removes any newline or whitespace at the start or end of the token
  console.log(`Token: ${trimmedToken}`);
  sessionStorage.setItem("token", trimmedToken);
}


function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("token");
}

