// ApplicationPage.js
import React, { useState, useEffect } from "react";
import TableHeader from "./TableHeader";
import { useNavigate } from "react-router-dom";
import {
  fetchApplicationData,
  fetchApplications,
} from "../helpers/ApplicationService";

const ApplicationList = ({ filterStatus, setFilterStatus }) => {
  const [data, setData] = useState([]);
  const [applications, setApplications] = useState([]); // Holds the full list of applications
  const [lastFetchTime, setLastFetchTime] = useState(new Date()); // Track the last fetch time
  const [pages, setPages] = useState(0); // Track the number of pages

  const navigate = useNavigate();

  // Get the page number from URL and set to 1 if not present
  const pageNumber =
    parseInt(new URLSearchParams(window.location.search).get("page")) || 0;
  // Set the page number to the URL
  const setPageNumber = (pageNumber) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", pageNumber);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
    fetchAndUpdateApplications();
  };

  useEffect(() => {
    setPageNumber(pageNumber);
  }, [pageNumber]);

  const fetchAndUpdateApplications = () => {
    fetchApplications(pageNumber)
      .then((fetchedApplications) => {
        setApplications(fetchedApplications.content);
        setPages(fetchedApplications.totalPages);

        if (filterStatus.length === 0) {
          setData(fetchedApplications);
        } else {
          const filteredData = fetchedApplications.filter((app) =>
            filterStatus.includes(app.applicationStatus)
          );
          setData(filteredData);
        }
        setLastFetchTime(new Date()); // Update the last fetch time
      })
      .catch((error) => {
        console.error("Error fetching applications:", error);
        setData([]); // Set to an empty array in case of an error
      });
  };

  useEffect(() => {
    fetchAndUpdateApplications();
  }, []); // Dependency array is empty to only fetch once

  useEffect(() => {
    // Filter applications based on status
    const filteredData = applications.filter((app) =>
      filterStatus.includes(app.applicationStatus)
    );
    setData(filterStatus.length > 0 ? filteredData : applications);
  }, [filterStatus, applications]);

  const handleRefresh = () => {
    fetchAndUpdateApplications(); // Call the fetch function when the refresh button is clicked
  };

  //sorting
  const [sortColumn, setSortColumn] = useState(""); // Track the current sorting column
  const [sortOrder, setSortOrder] = useState("asc"); // Track the sorting order (asc or desc)
  const handleSort = (column) => {
    if (column === sortColumn) {
      // Toggle the sorting order if the same column is clicked again
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the new sorting column and default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
      console.log(sortColumn);
    }
  };

  useEffect(() => {
    //Sort by date asc or dsc
    setData((prevState) => {
      return prevState.sort((a, b) => {
        if (sortOrder === "asc") {
          return new Date(a.applicationDate) - new Date(b.applicationDate);
        } else {
          return new Date(b.applicationDate) - new Date(a.applicationDate);
        }
      });
    });
  }, [sortOrder]);

  const handleStatusChange = (status) => {
    setFilterStatus((prevState) => {
      if (prevState.includes(status)) {
        return prevState.filter((s) => s !== status);
      }
      return [...prevState, status];
    });
  };

  const handleRowDoubleClick = (application) => {
    navigate("/applications/" + application.applicationId);
  };

  function flattenObject(obj, prefix = "") {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  }

  function objectToCsvLine(obj) {
    const flatObject = flattenObject(obj);
    const csvLine = Object.values(flatObject)
      .map(
        (value) => `"${value?.toString().replace(/"/g, '""')}"` // Handle commas and quotes in values
      )
      .join(",");
    return csvLine;
  }

  const [loadingExport, setLoadingExport] = useState(false);
  async function exportData(applications) {
    console.log("Starting export...");
    setLoadingExport(true);

    let csvContent = [];
    let headersSet = new Set();

    const applicationsArray = await fetchApplications(pageNumber, true).then(
      (fetchedApplications) => fetchedApplications.content
    );
    console.log("Applications processed");
    console.log(applicationsArray);

    //filter the applications based on the status
    const filteredApplications = applicationsArray.filter((app) =>
      filterStatus.includes(app.applicationStatus)
    );

    for (const [index, application] of filteredApplications.entries()) {
      console.log(
        `Fetching data for application ${index + 1}/${
          filteredApplications.length
        } (ID: ${application.applicationId})...`
      );
      let applicationData = await fetchApplicationData(
        application.applicationId,
        true
      );

      console.log(
        `Data fetched for application ID: ${application.applicationId}. Processing data...`
      );

      // Remove specified fields
      if (applicationData.user != null) {
        console.log("Removing sensitive data...");
        delete applicationData.user.identificationDocument;
        delete applicationData.user.proofOfIncomeDocument;
      }

      let tempProductName = applicationData.productAppliedFor.name;
      delete applicationData.productAppliedFor;
      applicationData.productName = tempProductName;

      //select the correct price and delete the others
      let tempVariationStorage = applicationData.variationAppliedFor.storage;
      const paymentFrequency = applicationData.paymentFrequency.toLowerCase();

      if (paymentFrequency === "monthly") {
        applicationData.price =
          applicationData.variationAppliedFor.pricePerMonth;
      } else if (paymentFrequency === "fortnightly") {
        applicationData.price =
          applicationData.variationAppliedFor.pricePerFortnight;
      } else if (paymentFrequency === "weekly") {
        applicationData.price =
          applicationData.variationAppliedFor.pricePerWeek;
      }

      application.phoneStorage = tempVariationStorage;

      console.log(applicationData.price);

      delete applicationData.variationAppliedFor;

      console.log(
        `Sensitive data removed for application ID: ${application.applicationId}.`
      );

      // Flatten the application data
      const flatObject = flattenObject(applicationData);
      console.log("flattened the object");
      // Collect headers
      Object.keys(flatObject).forEach((header) => headersSet.add(header));
      console.log("collected headers");
      // Convert the application data to a CSV line
      const csvLine = objectToCsvLine(applicationData);
      console.log("converted to csv line");
      csvContent.push(csvLine);
      console.log("pushed to csv content");
      console.log(
        `Application ID: ${application.applicationId} processed and added to CSV content.`
      );
    }

    console.log("All applications processed. Generating CSV...");

    // Generate CSV headers
    const headers = Array.from(headersSet).join(",");
    const csvHeaders = headers
      .split(",")
      .map((header) => `"${header}"`)
      .join(",");

    // Combine headers and content
    const finalCSV = `${csvHeaders}\n${csvContent.join("\n")}`;

    console.log("CSV generation complete. Preparing for download...");

    const uri = "data:text/csv;charset=utf-8," + encodeURIComponent(finalCSV);
    console.log("URI generated.");
    console.log(uri);
    window.open(uri);

    console.log("CSV file should now be downloading.");

    setLoadingExport(false);
    console.log("Export completed.");
  }

  // Note: setLoadingExport function should be defined in your context to actually set the loading state.

  return (
    <div className="flex flex-col w-full h-screen bg-gray-100">
      <div className="flex-1 w-full p-6">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-3xl font-bold">Application Page</h1>
          <button
            onClick={exportData}
            className={`px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700 ${
              loadingExport ? "opacity-50" : ""
            }`}
            disabled={loadingExport}
          >
            Export to csv
          </button>
          <button
            onClick={handleRefresh}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
          >
            Refresh
          </button>
        </div>
        <div className="mb-2 text-sm">
          Last updated: {lastFetchTime.toLocaleString()}
        </div>

        <div className="p-4">
          <div className="mb-4">
            <h2 className="text-xl">Filter by status:</h2>
            <div className="m-4">
              {["PendingApproval", "Incomplete", "Approved", "Declined"].map(
                (status) => (
                  <label key={status} className="mr-4">
                    <input
                      type="checkbox"
                      checked={filterStatus.includes(status)}
                      onChange={() => handleStatusChange(status)}
                    />
                    {status}
                  </label>
                )
              )}
            </div>
          </div>
          <div className="p-4 bg-white rounded shadow">
            <table className="w-full border-collapse">
              <thead>
                <tr className="text-right border-b">
                  <th>Customer Name</th>
                  <TableHeader
                    columnKey="dateOfApplication"
                    label="Application Date"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    handleSort={handleSort}
                  />
                  <th>Application Status</th>
                  <th>Product Name</th>
                  <th>Variation Name</th>
                </tr>
              </thead>
              <tbody>
                {console.log(data)}
                {data.length > 0 ? (
                  data.map((application) => (
                    <tr
                      className="transition border-b hover:bg-gray-100"
                      key={application.id}
                      onDoubleClick={() => handleRowDoubleClick(application)}
                    >
                      <td className="px-4 py-6 text-right">
                        {application.customerName}
                      </td>
                      <td className="px-4 text-right">
                        {new Date(application.applicationDate).toLocaleString()}
                      </td>
                      <td className="px-4 text-right">
                        {application.applicationStatus}
                      </td>
                      <td className="px-4 text-right">
                        {application.productName}
                      </td>
                      <td className="px-4 text-right">
                        {application.variationName}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>"No Data Found"</tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Left and right page arrows with current page number */}
      <div className="flex justify-between p-4 bg-white">
        <button
          disabled={pageNumber === 0}
          onClick={() => setPageNumber(pageNumber - 1)}
          className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 disabled:bg-gray-500"
        >
          Previous
        </button>
        <div>Page {pageNumber}</div>
        <button
          disabled={pageNumber === pages - 1}
          onClick={() => setPageNumber(pageNumber + 1)}
          className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 disabled:bg-gray-500"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ApplicationList;
