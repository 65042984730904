//New Product Page

import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import CreateProduct from "../components/CreateProduct";

const NewProductPage = () => {
    return (
        <div>
            <CreateProduct />
        </div>
    );
};

export default NewProductPage;