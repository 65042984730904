// ProductList.js
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useProductData from "../hooks/useProductData";
import TableHeader from "./TableHeader";
import ConfirmationDialog from "./ConfirmDialogue";
import { API_BASE_URL } from "../config";
import { archiveProduct, deleteProduct } from "../helpers/ProductService";

// ProductList Component
const ProductList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [productId, setProductId] = useState(null); // or some default value
  const [archived, setArchived] = useState(false);
  const { products, setProducts } = useProductData(productId, archived);

  const [productToArchive, setProductToArchive] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const toggleArchived = () => {
    setArchived(!archived);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCreateProduct = () => {
    navigate(`/product/new`);
  };

  const [sortColumn, setSortColumn] = useState(""); // Track the current sorting column
  const [sortOrder, setSortOrder] = useState("asc"); // Track the sorting order (asc or desc)

  const [filteredProducts, setFilteredProducts] = useState([]); // Track the filtered products

  useEffect(() => {
    // Filter the products based on the search term
    setFilteredProducts(
      products.filter((product) => {
        return (
          product.title.toLowerCase().includes(searchTerm) ||
          product.storage.join(", ").toLowerCase().includes(searchTerm) ||
          product.colors
            .map((color) => color.name)
            .join(", ")
            .toLowerCase()
            .includes(searchTerm)
        );
      })
    );
  }, [searchTerm, products]);

  // Function to handle column header click and perform sorting
  const handleSort = (column) => {
    if (column === sortColumn) {
      // Toggle the sorting order if the same column is clicked again
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the new sorting column and default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const onDoubleClick = (product) => {
    navigate(`/product/${product.id}`);
  };

  // Function to sort products based on the selected column and order
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortColumn === "title") {
      return sortOrder === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    } else if (sortColumn === "minPrice") {
      return sortOrder === "asc"
        ? a.minPrice - b.minPrice
        : b.minPrice - a.minPrice;
    } else if (sortColumn === "storage") {
      return sortOrder === "asc"
        ? a.storage.join(", ").localeCompare(b.storage.join(", "))
        : b.storage.join(", ").localeCompare(a.storage.join(", "));
    } else if (sortColumn === "colors") {
      const aColors = a.colors.map((color) => color.name).join(", ");
      const bColors = b.colors.map((color) => color.name).join(", ");

      return sortOrder === "asc"
        ? aColors.localeCompare(bColors)
        : bColors.localeCompare(aColors);
    }
    // Add sorting logic for other columns as needed

    // Default case: no sorting
    return 0;
  });

  const handleEditClick = (product) => {
    const params = new URLSearchParams();
    params.set("productId", product.id);

    // Generate the search string from the URLSearchParams object
    const searchString = params.toString();

    navigate(`/product/new?${searchString}`);
  };

  const openArchiveDialogue = (product) => {
    setProductToArchive(product);
    setIsConfirmationOpen(true);
  };

  const handleCancelArchive = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmArchive = () => {
    setIsConfirmationOpen(false);
    if (productToArchive) {
      archiveProduct(productToArchive, products, setProducts);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 w-full">
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onCancel={handleCancelArchive}
        onConfirm={handleConfirmArchive}
        confirmDialogue={`Are you sure you want to archive this product?`}
        itemDetails={`Name: ` + productToArchive?.title}
      />

      {/* Main Content */}
      <div className="flex-1 p-6 w-full">
        {/* Search and Add Button */}
        <div className="flex justify-between items-center mb-4">
          {/* Toggle archived checkbox */}
          <div className="w-1/3">
            <input
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border rounded w-full"
            />
            <input
              type="checkbox"
              id="archived"
              name="archived"
              value="archived"
              className="m-2"
              onChange={toggleArchived}
            />
            <label htmlFor="archived">Show Archived</label>
          </div>
          <button
            className="bg-blue-500 text-white p-4 rounded hover:bg-blue-600 transition"
            onClick={handleCreateProduct}
          >
            Create New Product
          </button>
        </div>

        {/* Table */}
        <div className="bg-white p-4 rounded shadow">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <TableHeader
                  columnKey="title"
                  label="Name"
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  handleSort={handleSort}
                />
                <TableHeader
                  columnKey="monthUpFront"
                  label="Month Up Front"
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  handleSort={handleSort}
                />
                <TableHeader
                  columnKey="minPrice"
                  label="Min Price"
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  handleSort={handleSort}
                />
                <TableHeader
                  columnKey="colors"
                  label="Colors"
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  handleSort={handleSort}
                />
                <TableHeader
                  columnKey="storage"
                  label="Storage"
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  handleSort={handleSort}
                />
              </tr>
            </thead>
            <tbody>
              {sortedProducts.map((product) => (
                <tr
                  onDoubleClick={() => onDoubleClick(product)}
                  key={product.id}
                  className={`hover:bg-gray-100 transition border-b ${
                    product.archived ? "italic bg-green-100" : ""
                  }`}
                >
                  <td className="p-2">{product.title}</td>
                  <td className="px-4 text-left">
                    {
                      //Convert boolean to colored checkbox
                      product.monthUpFront ? (
                        <div className="rounded-full border bg-blue-200 w-6 h-6 flex justify-center items-center">
                          ✔
                        </div>
                      ) : (
                        <div className="rounded-full border bg-purple-200 w-6 h-6 flex justify-center items-center">
                          ✖ 
                        </div>
                      )
                    }
                  </td>
                  <td className="px-4 text-left">{product.minPrice}</td>
                  <td className="flex items-center">
                    {product.colors.map((color, index) => (
                      <div
                        className="mx-5 text-right whitespace-normal"
                        key={color.id}
                      >
                        <div
                          className={`rounded-full border hover:border-indigo-600 cursor-pointer w-6 h-6`}
                          style={{
                            backgroundColor: color.hex,
                          }}
                        ></div>
                        <div className="mx-auto">{color.name}</div>
                      </div>
                    ))}
                  </td>
                  <td className="p-2 text-left">
                    {product.storage.join(", ")}
                  </td>
                  <td className="p-2 text-right">
                    <button
                      className="bg-gray-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation of the click event
                        openArchiveDialogue(product);
                      }}
                    >
                      Archive
                    </button>
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mx-4"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation of the click event
                        handleEditClick(product);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
