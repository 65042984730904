//New variation Page
import React, { useState } from "react";
import CreateVariation from "../components/CreateVariation";

const NewVariationPage = () => {
    return (
        <div>
            <CreateVariation />
        </div>
    );
}

export default NewVariationPage;