// ProductDetail.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useProductData from "../hooks/useProductData";
import { Carousel } from "react-responsive-carousel";
import ConfirmationDialog from "./ConfirmDialogue";
import { API_BASE_URL } from "../config";
import { deleteVariation } from "../helpers/VariationService";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const { products, setProducts } = useProductData(id);
  const navigate = useNavigate();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [variationToDelete, setVariationToDelete] = useState(null);

  useEffect(() => {
    if (products.length > 0) {
      setProduct(products[0]);
    }
  }, [products]);

  const handleClick = (variation) => {
    console.log(variation);
    console.log("navigate");
    console.log(product);
    navigate(`/variation/${variation.id}`, {
      state: { variation: variation, productId: product.id },
    });
  };

  const handleEditClick = (variation) => {
    // Create a new URLSearchParams object
    const params = new URLSearchParams();
    params.set("variationId", variation.id);

    // Generate the search string from the URLSearchParams object
    const searchString = params.toString();

    // Navigate to a new URL with the search parameters
    navigate(`/variation/new?${searchString}`, {
      state: { productId: product.id },
    });
  };

  const handleAddNewVariationClick = () => {
    navigate(`/variation/new`, { state: { productId: product.id } });
  };

  const openConfirmationDialog = (variation) => {
    setVariationToDelete(variation);
    setIsConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmationOpen(false);
    if (variationToDelete) {
      deleteVariation(product, variationToDelete, setProduct);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-100 w-full h-full mx-auto p-16 w-full">
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmDialogue={`Are you sure you want to delete this variation?`}
        itemDetails={
          `ID: ` +
          variationToDelete?.id +
          `, Storage: ` +
          variationToDelete?.storage
        }
      />
      <div className="mb-4">
        <h1 className="text-3xl font-bold mb-2">{product.title ?? ""}</h1>
        <h2 className="text-xl">ID: {product.id}</h2>
        <h2 className="text-xl mt-3">
          BestSeller: {product.bestSeller ? "True" : "False"}
        </h2>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 py-16">
          <div className="col-span-2">
            <h2 className="text-xl">Icon:</h2>
            {/* This div takes 1 column on all screen sizes */}
            <Carousel
              infiniteLoop={false} // Infinite loop
              showArrows={false} // Show navigation arrows
              showStatus={false} // Hide status (e.g., "3/5")
              showThumbs={false}
              showIndicators={false}
            >
              {product.image && (
                <img
                  src={`data:image/png;base64,${product.image}`}
                  alt=""
                  className="max-w-xl"
                />
              )}
            </Carousel>
          </div>
          <div></div>
          <div className="col-span-2">
            {/* This div also takes 1 column on all screen sizes */}
            <div className="max-w-xl">
              <h2 className="text-xl">Images:</h2>
              <Carousel
                infiniteLoop={true} // Infinite loop
                showArrows={true} // Show navigation arrows
                showStatus={false} // Hide status (e.g., "3/5")
                showThumbs={true} // Show bullet points for navigation
              >
                {product.images
                  ? product.images.map((image, index) => (
                      <div key={index}>
                        <img
                          src={`data:image/png;base64,${image}`}
                          alt={`Product ${index}`}
                        />
                      </div>
                    ))
                  : ""}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 py-16">
          <div className="col-span-2">
            <h2 className="text-xl">In The Box:</h2>
            {/* This div takes 1 column on all screen sizes */}
            <Carousel
              infiniteLoop={false} // Infinite loop
              showArrows={false} // Show navigation arrows
              showStatus={false} // Hide status (e.g., "3/5")
              showThumbs={false}
              showIndicators={false}
            >
              {product.inTheBox && (
                <img
                  src={`data:image/png;base64,${product.inTheBox}`}
                  alt=""
                  className="max-w-xl"
                />
              )}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Min Display Price: </h3> ${" "}
          {product.minPrice ?? "0"} /{" "}
          {product.rentalPeriods ? product.rentalPeriods[0] : ""}
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold">Colors:</h3>
          <ul className="list-disc list-inside">
            {product.colors
              ? product.colors.map((color) => (
                  <li key={color.name}>
                    {color.name} ({color.hex})
                  </li>
                ))
              : ""}
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold">Storage Options:</h3>
          <ul className="list-disc list-inside">
            {product.storage
              ? product.storage.map((storageOption) => (
                  <li key={storageOption}>{storageOption}</li>
                ))
              : ""}
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold">Rental Periods:</h3>
          <ul className="list-disc list-inside">
            {product.rentalPeriods
              ? product.rentalPeriods.map((rentalPeriod) => (
                  <li key={rentalPeriod}>{rentalPeriod}</li>
                ))
              : ""}
          </ul>
        </div>
      </div>

      {/* Description section */}
      <div className="mb-4">
        <h2 className="text-2xl font-semibold">Description:</h2>
        {/* Vertically stacked descriptions */}
        <div className="grid grid-cols-1 mt-4 gap-4">
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Description 1:</h3>
            <p>{product.description1 ?? ""}</p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold">Description 2:</h3>
            <p>{product.description2 ?? ""}</p>
          </div>
        </div>
      </div>

      {/* Variation table */}
      <div className="bg-white p-6 rounded shadow">
        <div className="grid grid-cols-4">
          <h2 className="text-2xl font-semibold col-span-3">Variations</h2>
          <button
            className="bg-blue-500 text-white p-4 rounded hover:bg-blue-600 transition mb-2 col-span-1"
            onClick={handleAddNewVariationClick}
          >
            Add New Variation
          </button>
        </div>

        <table className="w-full border-collapse">
          <thead>
            <tr className="border-b">
              <th className="p-2 text-left">ID</th>
              <th className="p-2 text-right">Storage</th>
              <th className="p-2 text-right">Total Value</th>
              <th className="p-2 text-right">Price per Quarter</th>
              <th className="p-2 text-right">Price per Month</th>
              <th className="p-2 text-right">Price per Fortnight</th>
              <th className="p-2 text-right">Price per Week</th>
              <th className="p-2 text-right">Price per Day</th>
              <th className="p-2 text-right">Minimum Price</th>
              <th className="p-2 text-right">Actions</th>
            </tr>
          </thead>
          {product.variations && product.variations.length > 0 && (
            <tbody>
              {product.variations.map((variation) => (
                <tr
                  className="hover:bg-gray-100 transition border-b"
                  key={variation.id}
                  onDoubleClick={() => handleClick(variation)}
                >
                  <td className="p-2">{variation.id}</td>
                  <td className="p-2 text-right">{variation.storage}</td>
                  <td className="p-2 text-right">{variation.totalValue}</td>
                  <td className="p-2 text-right">
                    {variation.pricePerQuarter}
                  </td>
                  <td className="p-2 text-right">{variation.pricePerMonth}</td>
                  <td className="p-2 text-right">
                    {variation.pricePerFortnight}
                  </td>
                  <td className="p-2 text-right">{variation.pricePerWeek}</td>
                  <td className="p-2 text-right">{variation.pricePerDay}</td>
                  <td className="p-2 text-right">{variation.minimumPrice}</td>
                  <td className="pl-4 text-right">
                    <button
                      className="bg-gray-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationDialog(variation); // Open the confirmation dialog
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 mx-4 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(variation); // Open the confirmation dialog
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProductDetail;
