import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmDialogue";
import {API_BASE_URL} from "../config";
import { deletePhysicalDevice, fetchPhysicalDevices } from "../helpers/PhysicalDeviceService";

const VariationDetail = () => {
  const [physicalDevices, setPhysicalDevices] = useState([]);
  const [physicalDeviceToDelete, setPhysicalDeviceToDelete] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const variation = location.state.variation;
  const productId = location.state.product;

  useEffect(() => {
    fetchPhysicalDevices(variation, setPhysicalDevices);
  }, []);

  

  const handleAddNewPhysicalDeviceClick = () => {
    navigate(`/physical-device/new`, {
      state: { variation: variation, productId: productId },
    });
  };

  const handleEditClick = (physicalDevice) => {
    const params = new URLSearchParams();
    params.set("physicalDeviceId", physicalDevice.id);
    const searchString = params.toString();
    navigate(`/physical-device/new?${searchString}`, {
      state: { variation: variation, productId: productId },
    });
  };

  const openConfirmationDialog = (physicalDevice) => {
    setPhysicalDeviceToDelete(physicalDevice);
    setIsConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmationOpen(false);
    if (physicalDeviceToDelete) {
      deletePhysicalDevice(physicalDeviceToDelete, physicalDevices, setPhysicalDevices);
    }
  };

  


  return (
    <div className="flex flex-col h-screen w-full mx-auto p-8">
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmDialogue={`Are you sure you want to delete this physical device?`}
        itemDetails={`IMEI: ` + physicalDeviceToDelete?.imei + `, Serial: ` + physicalDeviceToDelete?.serial}
      />
      <h1 className="text-3xl font-bold mb-8">
        Variation: {variation.storage}
      </h1>

      <div className="grid grid-cols-2 mb-8 bg-gray-100 p-4 rounded">
        <div>
          <h2 className="text-xl font-semibold mb-4">Variation Details</h2>
          <p className="">
            <strong>ID:</strong> {variation.id}
          </p>
          <p className="">
            <strong>Storage:</strong> {variation.storage}
          </p>
          <p className="">
            <strong>Total Value:</strong> ${variation.totalValue}
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-4">Variation Pricing</h2>
          <p className="">
            <strong>Price per Day:</strong> ${variation.pricePerDay}
          </p>
          <p className="">
            <strong>Price per Week:</strong> ${variation.pricePerWeek}
          </p>
          <p className="">
            <strong>Price per Fortnight:</strong> ${variation.pricePerFortnight}
          </p>
          <p className="">
            <strong>Price per Month:</strong> ${variation.pricePerMonth}
          </p>
          <p className="">
            <strong>Price per Quarter:</strong> ${variation.pricePerQuarter}
          </p>
        </div>
      </div>

      <div className="bg-white p-6 rounded shadow">
        <h2 className="text-xl font-semibold mb-4">Physical Devices</h2>
        <button
          className="bg-blue-500 text-white p-4 rounded hover:bg-blue-600 transition mb-2 col-span-1"
          onClick={handleAddNewPhysicalDeviceClick}
        >
          Add New Physical Device
        </button>
        <div className="p-2">
          <table className="w-full border-collapse">
            <thead className="">
              <tr>
                <th className="p-2 text-left">ID</th>
                <th className="p-2 text-left">IMEI</th>
                <th className="p-2 text-left">Serial</th>
                <th className="p-2 text-left">Color</th>
                <th className="p-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {physicalDevices.length > 0 ? physicalDevices.map((device) => (
                <tr key={device.id} className="hover:bg-gray-100 transition">
                  <td className="p-2 text-left">{device.id}</td>
                  <td className="p-2 text-left">{device.imei}</td>
                  <td className="p-2 text-left">{device.serial}</td>
                  <td className="p-2 text-left">
                    {device.color.name} ({device.color.hex})
                  </td>
                  <td className="p-2 text-left">
                    <button
                      className="bg-gray-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation of the click event
                        openConfirmationDialog(device);
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mx-4"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent propagation of the click event
                        handleEditClick(device);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              )) : <tr><td colSpan="5" className="p-2 text-left">No physical devices found.</td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VariationDetail;
