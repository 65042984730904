//variation page
import React from "react";
import Sidebar from "../components/SideBar";
import VariationDetail from "../components/VariationDetail";

const VariationPage = () => {
    return (
        <div className="flex flex-col h-screen bg-gray-100">
          <div className="flex">
            {/* Sidebar */}
            <Sidebar />
            <VariationDetail />
            </div>
        </div>
      );
}

export default VariationPage;