import { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../helpers/AuthHeader";

function useProductData(productId, getArchived) {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: AuthHeader(),
        "Content-Type": "application/json",
      },
    };

    let url = API_BASE_URL + "/api/v1/product";

    if (productId != null) {
      // Fetch a single product by ID
      url += `/${productId}`;
    } else {
      // Add archived parameter if getArchived is specified
      if (getArchived != null) {
        url += `?archived=${getArchived}`;
      }
    }

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          navigate("/login");
          return Promise.reject("Unauthorized");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (productId != null) {
          setProducts(mapProducts([data]));
        } else {
          setProducts(mapProducts(data));
        }
      })
      .catch((error) => console.error("Error fetching product(s):", error));
  }, [productId, navigate, getArchived]);

  const mapProducts = (p) => {
    return p.map((product) => {
      const variations = product.variations.map((variation) => ({
        id: variation.id,
        storage: variation.storage,
        totalValue: variation.totalValue,
        pricePerQuarter: variation.pricePerQuarter,
        pricePerMonth: variation.pricePerMonth,
        pricePerFortnight: variation.pricePerFortnight,
        pricePerWeek: variation.pricePerWeek,
        pricePerDay: variation.pricePerDay,
        minimumPrice: variation.minimumPrice,
      }));

      return {
        id: product.id,
        image: product.icon,
        title: product.name ?? "",
        minPrice: product.minPrice,
        images: product.images.map((image) => image.data),
        colors: product.colors.map((color) => ({
          id: color.id,
          name: color.name,
          hex: color.hex,
        })),
        storage: [
          ...new Set(product.variations.map((variation) => variation.storage)),
        ],
        variations: variations,
        rentalPeriods: product.rentalPeriods,
        description1: product.description1,
        description2: product.description2,
        bestSeller: product.bestSeller,
        inTheBox: product.inTheBox,
        archived: product.archived,
        monthUpFront: product.monthUpFront
      };
    });
  };

  return { products, setProducts };
}

export default useProductData;
