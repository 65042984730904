export default function getAuthHeader() {
  try {
    const token = sessionStorage.getItem("token");
    if(token === "" || token == null) {
      throw Error("Token not set")
    }
    console.log("Token: " + token)
    return token ? { Authorization: `Bearer ${token}` } : {};
  } catch (error) {
    console.log(error);
    return {};
  }
}
