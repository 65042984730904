import React from "react";

const ConfirmationDialog = ({ isOpen, onCancel, onConfirm, confirmDialogue, itemDetails, confirmColor }) => {
  return (
    <div
      className={`${
        isOpen ? "fixed" : "hidden"
      } inset-0 flex items-center justify-center z-50`}
    >
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="bg-white p-4 rounded shadow-lg z-10">
        <p className="text-xl mb-4">{confirmDialogue ?? "Are you sure you want to delete this item?"}</p>
        <p className="text-xl mb-4">{itemDetails}</p>
        <div className="flex justify-end">
          <button
            className={`text-white font-semibold px-4 py-2 rounded mr-4 ${confirmColor ? confirmColor : " bg-red-500 hover:bg-red-600"}`}
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;