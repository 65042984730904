import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import getAuthHeader from "./AuthHeader";
import { handleResponse } from "./HandleResponse";

export async function fetchApplications(pageNumber = 0, dontLogout = false) {
  try {
    const response = await fetch(API_BASE_URL + `/api/v1/application?page=` + pageNumber, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    });

    const result = await handleResponse(response, dontLogout);
    return result.json();
  } catch (error) {
    console.error("Error fetching application data:", error);
    return []; // Return an empty array in case of error
  }
}

export async function fetchApplicationData(id, dontLogout = false) {
  try {
    const response = await fetch(API_BASE_URL + `/api/v1/application/${id}`, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    }).then((response) => handleResponse(response, dontLogout));
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching application:", error);
    return null;
  }
}

export async function createSubscription(
  rentalApplicationId,
  productId,
  stripeCustomerId
) {
  const baseURL = API_BASE_URL + "/api/v1/payment/create-subscription/";
  const url = `${baseURL}${stripeCustomerId}?rentalApplicationId=${rentalApplicationId}&productId=${productId}`;

  return fetchSubscription(url);
}

export async function fetchSubscription(url) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    console.log("Subscription successful:", await response.text());
    // Perform any further actions here
  } catch (error) {
    console.error("Error creating subscription:", error);
    // Handle the error as needed
  }
}

export async function sendPutRequest(url, successMessage, errorMessage) {
  return fetch(url, {
    method: "PUT",
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => handleResponse(response))
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(errorMessage);
      }
    })
    .then((data) => {
      console.log(successMessage, data);
    })
    .catch((error) => console.error(errorMessage, error));
}
