// ProductListPage.js
import React, { useState } from 'react';
import Sidebar from '../components/SideBar';
import ProductList from '../components/ProductList';

const ProductListPage = () => {
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />
        <ProductList />
        </div>
    </div>
  );
};

export default ProductListPage;
