// ProductDetails.js
import React from "react";
import Sidebar from "../components/SideBar";
import ProductDetail from "../components/ProductDetail";

const ProductDetailsPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-800">
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />
        <ProductDetail />
      </div>
    </div>
  );
};

export default ProductDetailsPage;
