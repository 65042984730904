import { API_BASE_URL } from "../config";
import getAuthHeader from "./AuthHeader"
import { handleResponse } from "./HandleResponse";

export async function getProduct(productId, setFormData) {
  console.log("Product ID: " + productId);
  fetch(API_BASE_URL + `/api/v1/product/${productId}`)
    .then((response) => handleResponse(response))
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      const icon = data.icon;
      const colors = data.colors;
      const rentalPeriods = data.rentalPeriods;
      const description1 = data.description1;
      const description2 = data.description2;
      const bestSeller = data.bestSeller;
      const inTheBox = data.inTheBox;
      const archived = data.archived;

      const images = data.images.map((image) => ({
        data: image.data ? `data:image/png;base64,${image.data}` : "",
        orderNumber: image.orderNumber ?? 1,
        fileName: image.fileName ?? "No fileName",
      }));

      setFormData({
        image: data.icon ? `data:image/png;base64,${icon}` : "",
        title: data.name,
        images: images,
        colors: colors.map((color) => ({
          id: color.id || "",
          name: color.name || "",
          hex: color.hex || "",
        })),
        rentalPeriods: rentalPeriods,
        description1: description1,
        description2: description2,
        bestSeller: bestSeller,
        inTheBox: inTheBox ? `data:image/png;base64,${inTheBox}` : "",
        monthUpFront: data.monthUpFront,
        archived: archived,
      });
    })
    .catch((error) => {
      console.error("Error fetching product data:", error);
    });
}

export const createProduct = async (productData) => {
  return await fetch(API_BASE_URL + "/api/v1/product", {
    method: "POST",
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
      
    },
    body: JSON.stringify(productData),
  }).then((response) => handleResponse(response));
};

// Extracted function to update a product
export const updateProduct = async (productData, productId) => {
  console.log("UPDATING PRODUCT");
  console.log(productData);
  console.log(productId);
  return await fetch(API_BASE_URL + `/api/v1/product/${productId}`, {
    method: "PUT",
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
      
    },
    body: JSON.stringify(productData),
  }).then((response) => handleResponse(response));
};

export async function deleteProduct(product, products, setProducts) {
  // Deletes the product
  fetch(API_BASE_URL + `/api/v1/product/${product.id}`, {
    method: "DELETE",
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
      
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data); // Log the response data
      // Update the products array to remove the deleted product
      setProducts(products.filter((p) => p.id !== product.id));
    })
    .catch((error) => console.error("Error deleting product:", error));
}

export async function archiveProduct(product, products, setProducts) {
  // Deletes the product
  fetch(API_BASE_URL + `/api/v1/product/archive/${product.id}`, {
    method: "PUT",
    headers: {
      ...getAuthHeader(),
      "Content-Type": "application/json",
      
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data); // Log the response data
      // Update the products array to remove the deleted product

      setProducts(products.filter((p) => p.id !== product.id));
    })
    .catch((error) => console.error("Error deleting product:", error));
}
